import { useSearchParams } from 'react-router-dom';

export const useAid = (): string => {
  const [rawSearchParams] = useSearchParams();

  const searchParams: Record<string, string> = {};
  rawSearchParams.forEach((value, key) => {
    searchParams[key.toLowerCase()] = value;
  });

  return searchParams.aid;
};
